import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notFoundBox: {
      width: `90%`,
      margin: `1.5rem auto`,
    },
  }),
);

const NotFoundBox: React.VFC = () => {
  const classes = useStyles();

  return (
    <section className={classes.notFoundBox}>
      <Typography>
        お探しのページは存在しません。
        <br />
        URLをご確認の上、再度お越しください。
      </Typography>
    </section>
  );
};

export default NotFoundBox;
