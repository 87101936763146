import React from 'react';
import { PageProps } from 'gatsby';

import Layout from '@/components/others/Layout';
import FirstView from '@/components/404/section/FirstView';
import NotFoundBox from '@/components/404/section/NotFoundBox';

const NotFound: React.FC<PageProps> = () => (
  <Layout>
    <FirstView />
    <NotFoundBox />
  </Layout>
);

export default NotFound;
