import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    firstViewBox: {
      width: `100%`,
      maxHeight: `30vh`,
      position: `relative`,
    },
    firstViewImageStyle: {
      width: `100%`,
      maxHeight: `30vh`,
    },
    firstViewTitle: {
      position: `absolute`,
      right: `10%`,
      top: `30%`,
      fontSize: `calc(1.8rem + 2vw)`,
      color: theme.palette.secondary.main,
    },
  }),
);

const FirstView: React.VFC = () => {
  const classes = useStyles();

  return (
    <div className={classes.firstViewBox}>
      <StaticImage
        src="../../../images/404.jpg"
        alt="main image"
        objectPosition="50% 60%"
        className={classes.firstViewImageStyle}
      />
      <Typography variant="h1" className={classes.firstViewTitle}>
        404 Not Found
      </Typography>
    </div>
  );
};

export default FirstView;
